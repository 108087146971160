import { RouteGroupInterface, SidebarEnum } from '../types';
import { extractPathsBySidebarValue } from '../utils';

/**
 * ROUTES
 */
export const ROUTES = {
  /* Dashboard */
  DASHBOARD: {
    /**
     * Root
     */
    ROOT: {
      absPath: '/',
      relPath: '/',
      sidebar: SidebarEnum.PreviewNewsStatistics,
    },

    /**
     * Administration
     */
    ADMINISTRATION: {
      ROOT: {
        absPath: '/administration',
        relPath: 'administration',
        sidebar: undefined,
      },

      FORUM: {
        ROOT: {
          absPath: '/administration',
          relPath: 'administration',
          sidebar: SidebarEnum.Preview,
        },
        FORUM_MEMBER_ADD: {
          absPath: '/administration/add-member',
          relPath: 'add-member',
          sidebar: undefined,
        },
        FORUM_MEMBER_EDIT: {
          absPath: '/administration/:id',
          relPath: ':id',
          sidebar: undefined,
        },
        RESET_PASSWORD: {
          absPath: '/administration/change-password',
          relPath: 'change-password',
          sidebar: undefined,
        },
      },

      // Roles
      ROLES: {
        ROOT: {
          absPath: '/administration/roles',
          relPath: 'roles',
          sidebar: undefined,
        },
      },

      // Organizations
      ORGANIZATIONS: {
        ROOT: {
          absPath: '/administration/organizations',
          relPath: 'organizations',
          sidebar: SidebarEnum.Preview,
        },
        ORGANIZATION_ADD: {
          absPath: '/administration/organizations/add-organization',
          relPath: 'add-organization',
          sidebar: undefined,
        },
        ORGANIZATION_EDIT: {
          absPath: '/administration/organizations/:id',
          relPath: ':id',
          sidebar: undefined,
        },
      },

      // Regions
      REGIONS: {
        ROOT: {
          absPath: '/administration/regions',
          relPath: 'regions',
          sidebar: SidebarEnum.Preview,
        },
      },

      // Organization
      ORGANIZATION: {
        ROOT: {
          absPath: '/administration/organization',
          relPath: 'organization',
          sidebar: SidebarEnum.Preview,
        },
        DOCUMENTS: {
          absPath: '/administration/organization/:slug',
          relPath: ':slug',
          sidebar: undefined,
        },
        ADD_DOCUMENT: {
          absPath: '/administration/organization/:slug/add',
          relPath: 'add',
          sidebar: undefined,
        },
        EDIT_DOCUMENT: {
          absPath: '/administration/organization/:slug/:id',
          relPath: ':id',
          sidebar: undefined,
        },
        FORUM_COUNCIL: {
          absPath: '/administration/organization/forum-council',
          relPath: 'forum-council',
          sidebar: SidebarEnum.Preview,
        },
        TNC: {
          absPath: '/administration/organization/terms-and-conditions',
          relPath: 'terms-and-conditions',
          sidebar: SidebarEnum.Preview,
        },
        ARCHIVE: {
          absPath: '/administration/organization/archive',
          relPath: 'archive',
          sidebar: SidebarEnum.Preview,
        },
      },

      // Forum Old
      FORUMS: {
        ROOT: {
          absPath: '/administration/forum',
          relPath: 'forum',
          sidebar: SidebarEnum.Preview,
        },
        FORUM_MEMBERS: {
          absPath: '/administration/forum/forum-members',
          relPath: 'forum-members',
          sidebar: SidebarEnum.Preview,
        },
        FORUM_COUNCIL: {
          absPath: '/administration/forum/forum-council',
          relPath: 'forum-council',
          sidebar: SidebarEnum.Preview,
        },
        FORUM_REPORTS: {
          absPath: '/administration/forum/forum-reports',
          relPath: 'forum-reports',
          sidebar: SidebarEnum.Preview,
        },
      },

      // Database Instructions
      DATABASE_INSTRUCTIONS: {
        ROOT: {
          absPath: '/administration/database-instructions',
          relPath: 'database-instructions',
          sidebar: SidebarEnum.Preview,
        },
        INFO: {
          absPath: '/administration/database-instructions/:slug',
          relPath: ':slug',
          sidebar: SidebarEnum.Preview,
        },
        FAQ: {
          absPath: '/administration/database-instructions/faq',
          relPath: 'faq',
          sidebar: SidebarEnum.Preview,
        },
      },
    },

    /**
     * Fraud Cases
     */
    FRAUD_CASES: {
      ROOT: {
        absPath: '/fraud-cases',
        relPath: 'fraud-cases/',
        sidebar: SidebarEnum.PreviewNewsStatistics, //SidebarEnum.News,
      },
      FRAUD_CASE_ADD: {
        absPath: '/fraud-cases/add-fraud-case',
        relPath: 'add-fraud-case',
        sidebar: undefined,
      },
      FRAUD_CASE_EDIT: {
        absPath: '/fraud-cases/edit/:slug',
        relPath: 'edit/:slug',
        sidebar: undefined,
      },
      FRAUD_CASE_DETAILS: {
        absPath: '/fraud-cases/:id',
        relPath: ':id',
        sidebar: undefined,
      },
    },

    /**
     * Education
     */
    EDUCATION: {
      ROOT: {
        absPath: '/education',
        relPath: 'education/',
        sidebar: undefined,
      },
      ADD_DOCUMENT: {
        absPath: '/education/add-document',
        relPath: 'add-document',
        sidebar: undefined,
      },
      LISTS: {
        absPath: '/education/:slug',
        relPath: ':slug',
        sidebar: undefined,
      },
      EDIT_DOCUMENT: {
        absPath: '/education/:slug/:id',
        relPath: ':slug/:id',
        sidebar: undefined,
      },
      SEMINARS: {
        absPath: '/education/seminars',
        relPath: 'seminars',
        sidebar: undefined,
      },
      /* News */
      NEWS: {
        absPath: '/education/news',
        relPath: 'news',
        sidebar: SidebarEnum.PreviewWithStatistics,
      },
      NEWS_DETAILS: {
        absPath: '/education/news/:id',
        relPath: ':id',
        sidebar: SidebarEnum.Preview,
      },
      ADD_NEWS: {
        absPath: '/education/add-news',
        relPath: 'education/add-news',
        sidebar: undefined,
      },
      EDIT_NEWS: {
        absPath: '/education/edit-news/:id',
        relPath: 'edit-news/:id',
        sidebar: undefined,
      },
    },

    /**
     * CHAT
     */
    CHAT: {
      ROOT: {
        absPath: '/chat',
        relPath: 'chat',
        sidebar: undefined,
      },
    },

    /* Admin */
    ADMIN: {
      ROOT: {
        absPath: '/admin',
        relPath: 'admin/',
        sidebar: undefined,
      },
      USER_ENTRY: {
        absPath: '/admin/add-member',
        relPath: 'add-member',
        sidebar: undefined,
      },
    },

    /* Search */
    SEARCH: {
      absPath: '/search',
      relPath: 'search',
      sidebar: SidebarEnum.PreviewNewsStatistics,
    },

    /* Unauthorized */
    UNAUTHORIZED: {
      absPath: '/unauthorized',
      relPath: 'unauthorized',
      sidebar: SidebarEnum.Preview,
    },
  },

  RESET: {
    ROOT: {
      absPath: '/resetpassword',
      relPath: 'resetpassword',
      sidebar: undefined,
    },
  },

  CREATEPASSWORD: {
    ROOT: {
      absPath: '/createpassword',
      relPath: 'createpassword',
      sidebar: undefined,
    },
  },

  /* Auth */
  LOGIN: {
    // Login
    ROOT: {
      absPath: '/login',
      relPath: 'login',
      sidebar: undefined,
    },

    // Email Verification
    EMAIL_VERIFICATION: {
      absPath: '/login/code-verification',
      relPath: 'code-verification',
      sidebar: undefined,
    },

    // Forgotten Password
    FORGOT_PASSWORD: {
      absPath: '/login/forgotpassword',
      relPath: 'forgotpassword',
      sidebar: undefined,
    },
  },

  /* Fallback route */
  NOT_FOUND: { absPath: '*', relPath: '*', sidebar: undefined },
};

/**
 * SIDEBAR
 */
// News
export const NEWS_SIDEBAR_PATHS = extractPathsBySidebarValue(
  ROUTES as RouteGroupInterface,
  SidebarEnum.News
);
// Preview
export const PREVIEW_SIDEBAR_PATHS = extractPathsBySidebarValue(
  ROUTES as RouteGroupInterface,
  SidebarEnum.Preview
);
// Preview With Statistics
export const PREVIEW_WITH_STATISTICS_SIDEBAR_PATHS = extractPathsBySidebarValue(
  ROUTES as RouteGroupInterface,
  SidebarEnum.PreviewWithStatistics
);

//Preview News and Statistics
export const PREVIEW_NEWS_STATISTICS_SIDEBAR_PATHS = extractPathsBySidebarValue(
  ROUTES as RouteGroupInterface,
  SidebarEnum.PreviewNewsStatistics
);

// Absolute paths extracted from ROUTES object
export const HOME_PAGE_LINK = ROUTES.DASHBOARD.ROOT.absPath;

export const ADMINISTRATION_PATH = ROUTES.DASHBOARD.ADMINISTRATION.ROOT.absPath;

export const ORGANIZATION_PATH =
  ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATIONS.ROOT.absPath;

export const ORGANIZATION_ADD_PATH =
  ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATIONS.ORGANIZATION_ADD.absPath;

export const REGIONS_PATH =
  ROUTES.DASHBOARD.ADMINISTRATION.REGIONS.ROOT.absPath;

export const FORUM_MEMBER_ADD_PATH =
  ROUTES.DASHBOARD.ADMINISTRATION.FORUM.FORUM_MEMBER_ADD.absPath;

export const RESET_PASSWORD_PATH =
  ROUTES.DASHBOARD.ADMINISTRATION.FORUM.RESET_PASSWORD.absPath;

export const FRAUD_CASE_PATH = ROUTES.DASHBOARD.FRAUD_CASES.ROOT.absPath;
export const CHAT_PATH = ROUTES.DASHBOARD.CHAT.ROOT.absPath;

export const FRAUD_CASE_ADD_PATH =
  ROUTES.DASHBOARD.FRAUD_CASES.FRAUD_CASE_ADD.absPath;

export const FORGOT_PASSWORD_PATH = ROUTES.LOGIN.FORGOT_PASSWORD.absPath;

export const NEWS_PATH = ROUTES.DASHBOARD.EDUCATION.NEWS.absPath;

export const EDIT_NEWS_PATH =
  ROUTES.DASHBOARD.EDUCATION.ROOT.absPath + '/edit-news';

export const FORUM_MEMBERS_PATH = ROUTES.DASHBOARD.ADMINISTRATION.ROOT.absPath;

export const EDUCATION_PATH = ROUTES.DASHBOARD.EDUCATION.ROOT.absPath;

export const ORGANIZATION_SECTION_PATH =
  ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATION.ROOT.absPath;
