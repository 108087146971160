export enum PermissionsEnum {
  /*
   * ARCHIVE
   */
  ArchiveSearch = 'api.archive',
  /*
   * ARTICLES
   */
  // Get
  ArticleData = 'api.article.data',
  // Post
  ArticleCreate = 'api.article.create',

  /*
   * DOCUMENTS
   */
  // Get
  DocumentsList = 'api.documents.list',
  DocumentData = 'api.document.data',
  DocumentFile = 'api.document.file',
  // Post
  DocumentsCreate = 'api.documents.create',
  DocumentUpdate = 'api.document.update',
  // Delete
  DocumentDelete = 'api.document.delete',

  /*
   * NEWS
   */
  // Get
  NewsList = 'api.news.list',
  NewsData = 'api.news.data',
  NewsPhoto = 'api.news.photo',
  // Post
  NewsCreate = 'api.news.create',
  NewsEdit = 'api.news.edit',
  NewsStatus = 'api.news.status',
  // Delete
  NewsDelete = 'api.news.delete',

  /*
   * FRAUD CASES
   */
  // Get
  FraudcasesList = 'api.fraudcases.list',
  FraudcaseData = 'api.fraudcase.data',
  FraudcaseCommentsData = 'api.fraudcase.comments.data',
  FraudcaseFilesList = 'api.fraudcase.files.list',
  FraudcaseFileData = 'api.fraudcase.file.data',
  // Post
  FraudcaseCreate = 'api.fraudcase.create',
  FraudcaseCommentsList = 'api.fraudcase.comments.list',
  FraudcaseCommentAdd = 'api.fraudcase.comment.add',
  FraudcaseFilesAdd = 'api.fraudcase.files.add',
  FraudcaseTypesList = 'api.fraudcase.types.list',
  FraudcaseCardtypesList = 'api.fraudcase.cardtypes.list',
  FraudcaseTerminalList = 'api.fraudcase.terminal.list',
  FraudcaseRegionStatistics = 'api.fraudcase.region.statistics',
  FraudCaseReportData = 'api.fraudcase.report.data',
  FraudCaseReportPerRegion = 'api.fraudcase.report.per_region',
  // Delete
  FraudcaseCommentDelete = 'api.fraudcase.comment.delete',
  FraudcaseFileDelete = 'api.fraudcase.file.delete',

  /*
   * ORGANIZATIONS
   */
  // Get
  OrganizationsList = 'api.organizations.list',
  OrganizationData = 'api.organization.data',
  // Post
  OrganizationCreate = 'api.organization.create',
  OrganizationEdit = 'api.organization.edit',
  OrganizationDelete = 'api.organization.delete',
  // Delete missing

  /*
   * REGIONS
   */
  // Get
  RegionsList = 'api.regions.list',
  RegionData = 'api.region.data',
  // Post
  RegionCreate = 'api.region.create',
  RegionEdit = 'api.region.edit',
  RegionDelete = 'api.region.delete',
  // Delete Missing

  /*
   * ROLES
   */
  // Get
  RolesList = 'api.roles.list',
  RolesData = 'api.role.data',
  // Post
  RoleCreate = 'api.role.create',
  RoleEdit = 'api.role.edit',
  RoleDelete = 'api.role.delete',
  // Delete

  /*
   * PERMISSIONS
   */
  // Get
  PermissionsList = 'api.permissions.list',
  PermissionsData = 'api.permission.data',

  /*
   * ROLES-PERMISSIONS
   */
  // Get
  RolePermissionsGet = 'api.role.permissions.get',
  //Post
  RolePermissionsSet = 'api.role.permissions.set',

  /*
   * USERS
   */
  // Get
  UsersList = 'api.users.list',
  UserData = 'api.user.data',
  UserRolesList = 'api.user.roles.list',
  // Post
  UserCreate = 'api.user.create',
  UserEdit = 'api.user.edit',
  UserRegions = 'api.user.regions',
  UserRoles = 'api.user.roles',
  UserAvatar = 'api.user.avatar',
  UserAvatarSet = 'api.user.avatar.set',
  UserActivate = 'api.user.activate.account',
  // Delete
  UserDelete = 'api.user.delete',

  /*
   * SEARCH
   */
  // Get
  Search = 'api.search',
}
