import { useState } from 'react';

import { useDebounce } from './useDebounce';
import { useTranslation } from 'react-i18next';

export const useSearchTerm = ({
  value = '',
  minChars,
}: {
  value?: string;
  minChars: number;
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState(value);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const isSearchTermTooShort = searchTerm && searchTerm.length < minChars;
  const searchInputPrompt = t('search.min_char_prompt', { minChars: minChars });

  return {
    searchTerm,
    setSearchTerm,
    debouncedSearchTerm,
    isSearchTermTooShort,
    searchInputPrompt,
  };
};
