import { PermissionsEnum } from '../../../types';
import { hasPermission } from '../../../utils';

import ForumMembersStore from '../../../modules/forumMembers/services/forumMembers.store';
const forumMembersStore: ForumMembersStore = new ForumMembersStore();

interface AvatarProps {
  id?: string;
  image?: string;
  name?: string;
  size?: number;
  rounded?: boolean;
}

/**
 * Avatar component displays a user's avatar, either as an image or initials.
 *
 * @param {AvatarProps} props - The props for the Avatar component.
 * @param {string} [props.image] - The URL of the image to display as the avatar. If not provided, the avatar will use initials instead.
 * @param {string} [props.name] - The name of the user, used to generate initials for the avatar. If not provided, default initials 'AU' will be used.
 * @param {number} [props.size=46] - The size of the avatar in pixels. Defaults to 46 if not specified.
 *
 * @returns {React.JSX.Element} The rendered Avatar component.
 */
const Avatar: React.FC<AvatarProps> = ({
  id,
  name,
  size = 38,
  rounded = false,
}: AvatarProps): React.JSX.Element => {
  const canViewUserImage = hasPermission([PermissionsEnum.UserAvatar]);

  const initials = name
    ? name
        .split(' ')
        .map((part) => part.charAt(0).toUpperCase())
        .join('')
    : 'AU';

  const { data: image } = forumMembersStore.useGetForumMemberAvatar({
    id: Number(id),
    enabled: id && canViewUserImage === true ? true : false,
  });

  return (
    <div
      className={`pointer-events-none overflow-hidden flex flex-shrink-0 items-center justify-center bg-secondary-200 ${
        rounded ? 'rounded-full' : 'rounded-lg'
      }`}
      style={{
        width: size,
        height: size,
        fontSize: size * 0.4,
      }}
    >
      {id && image?.data && canViewUserImage ? (
        <img
          src={
            canViewUserImage && image.data
              ? URL.createObjectURL(image.data)
              : undefined
          }
          alt={name || 'Avatar'}
          className="object-cover"
          style={{ width: size, height: size }}
        />
      ) : (
        <span className="uppercase">{initials}</span>
      )}
    </div>
  );
};

export default Avatar;
