import { useLoaderData, useNavigate } from 'react-router-dom';
import { FieldValues, SubmitHandler } from 'react-hook-form';

import Button from '../../components/shared/Button';
import ForumCouncilForm from '../../modules/info/UI/ForumCouncilForm';
import Icon from '../../components/shared/Icon';
import Info from '../../modules/info/UI';
import Meta from '../../components/layout/Meta';
import NoResultsInfoMessage from '../../components/shared/InfoMessage/NoResults';
import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';

import queryClient from '../../config/tanstackQueryConfig';
import { useModal } from '../../context/modal-context';
import { usePaginatedDropdown } from '../../hooks/usePaginatedDropdown';
import {
  ActionResponse,
  DropDownItemInterface,
  PermissionsEnum,
} from '../../types';

import InfoStore, {
  forumCouncilQueryOptions,
} from '../../modules/info/services/info.store';
import ForumMembersStore from '../../modules/forumMembers/services/forumMembers.store';
import {
  decodeHtmlEntities,
  extractMultipleErrors,
  hasNestedObjects,
  hasPermission,
  showToast,
} from '../../utils';
import { toast } from 'react-toastify';
import { usePermission } from '../../hooks/usePermission';
import { useRedirect } from '../../hooks/useRedirect';
import { useTranslation } from 'react-i18next';

const infoStore: InfoStore = new InfoStore();
const forumMembersStore: ForumMembersStore = new ForumMembersStore();

interface UserInterface {
  id: number;
  label: string;
  organization: string;
  email: string;
}

function ForumCouncil() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Permissions
  const canListUsers = usePermission([PermissionsEnum.UsersList]);
  const canViewData = usePermission([PermissionsEnum.ArticleData]);
  const canCreateArticle = usePermission([PermissionsEnum.ArticleCreate]);
  const shouldRedirect =
    canViewData === false &&
    canCreateArticle === false &&
    canViewData === false;
  useRedirect({ redirect: shouldRedirect });

  const pageTitle = t('articles.forum_council');

  const { data } = useLoaderData() as LoaderData;

  const { openModal, closeModal } = useModal();

  const transformContentData = (content: Record<string, any>) => {
    return Object.entries(content).map(([key, item], index) => ({
      id: `fc-${index + 1}`,
      fullname: item?.fullname,
      email: item?.email,
      organization: item?.organization,
      position: item.position && decodeHtmlEntities(item.position),
    }));
  };
  const forumCouncilMembers = data ? transformContentData(data?.content) : null;

  // Get Forum Members
  const useGetAllForumMembers = () => {
    return usePaginatedDropdown({
      fetchData: (page) => {
        return forumMembersStore.useGetForumMembers({
          page,
          searchTerm: '',
          enabled: canListUsers ? true : false,
        });
      },
      extractItems: (data) => {
        return (data?.users || [])
          .filter((user: any) => user.first_name || user.last_name) // Ensure at least one name is present
          .map((user: any) => ({
            id: user.id.toString(),
            label: `${user.first_name ?? ''} ${user.last_name ?? ''}`.trim(), // Avoid extra spaces
            organization: user?.organization?.name,
            email: user?.email,
          }));
      },
    });
  };

  const { finalItems: allUsers, isLoadingRef: isLoadingUsers } =
    useGetAllForumMembers() as any;

  const allUsersDropdown: DropDownItemInterface[] = allUsers.map(
    (user: any) => ({
      id: user.id,
      label: user.label,
    })
  );

  const defaultValues: any = data
    ? {
        users:
          forumCouncilMembers
            ?.map(({ email, position, id, fullname }) => {
              const matchingUser = allUsers.find(
                (user: any) => user.email === email
              );
              // Return matching user or prepare fallback values
              return matchingUser
                ? {
                    id: matchingUser.id,
                    position,
                    label: matchingUser.label,
                  }
                : { id, position, label: fullname }; // Prepare fallback if no match
            })
            .filter(Boolean) || null, // Ensure to remove null values
        regionId: data?.regionId,
      }
    : null;

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    const submittedUsers = formData.users;
    const submittedRegion = formData.regionId;

    // Find matching users in `allUsers` and add position
    const preparedData = submittedUsers.map((submittedUser: any) => {
      const matchingUser: UserInterface = allUsers.find(
        (user: any) => user.id === submittedUser.id
      );

      if (matchingUser) {
        const { id, label, ...restUserDetails } = matchingUser; // Exclude `userId`
        return {
          ...restUserDetails,
          fullname: label,
          position: submittedUser.position, // Add position to user data
        };
      }

      showToast(t('state.userNotFound', { name: submittedUser.label }), false);
      return null;
    });

    if (!preparedData || preparedData.some((member: null) => member === null)) {
      return;
    }

    const data = {
      contentJson: preparedData,
      regionId: submittedRegion,
    };

    const { success, message } = await infoStore.addForumCouncil({ data });

    if (success) {
      typeof message === 'string' && toast.success(message);
      closeModal();
      navigate('.', { replace: true, state: { key: Date.now() } });
    } else {
      if (hasNestedObjects(message)) {
        const errorMessages = extractMultipleErrors(message);
        toast.error(
          Object.values(errorMessages)[0] || `${t('errors.title_error')}!`
        );
      } else {
        showToast(message, success, true);
      }
    }
  };

  const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal(
      <ForumCouncilForm
        users={allUsersDropdown}
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        isLoading={isLoadingUsers?.current ? true : false}
      />,
      data ? t('forum_members.edit_members') : t('forum_members.new_members')
    );
  };

  return (
    <>
      <Meta title={pageTitle} />
      <PageLayout>
        <PageTitleWithActions title={pageTitle}>
          {canCreateArticle && canListUsers ? (
            <Button
              className="flex gap-2 items-center"
              onClick={handleOpenModal}
              disabled={isLoadingUsers.current}
            >
              <Icon
                name={isLoadingUsers.current ? 'searchSpinner' : 'pencil'}
                className={
                  isLoadingUsers.current ? 'w-4 text-white animate-spin' : ''
                }
              />
              {data
                ? t('forum_members.edit_members')
                : t('forum_members.add_members')}
            </Button>
          ) : null}
        </PageTitleWithActions>
        {canViewData ? (
          <>
            {data?.date && <p>{data.date}</p>}
            <Info>
              {forumCouncilMembers ? (
                <>
                  <ul className="flex flex-col gap-3">
                    {forumCouncilMembers.map((member) => (
                      <li key={member.id} className="pks-card rounded-lg p-2">
                        <div className="flex sm:items-center sm:justify-between flex-col gap-2 sm:flex-row">
                          <div className="flex flex-col gap-2">
                            <span className="font-bold text-lg">
                              {member.fullname}
                            </span>
                            <span className="font-bold sm:hidden">
                              {member.position}
                            </span>
                            <span className="text-secondary-400">
                              {member.organization}
                            </span>
                            <span className="text-secondary-400">
                              {member.email}
                            </span>
                          </div>
                          <div className="hidden sm:block">
                            <span className="text-secondary-400">
                              {member.position}
                            </span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <NoResultsInfoMessage />
              )}
            </Info>
          </>
        ) : null}
      </PageLayout>
    </>
  );
}

export default ForumCouncil;

// Loader Function
interface LoaderData {
  data: any | null;
}

export async function loader(): Promise<LoaderData> {
  if (!hasPermission([PermissionsEnum.ArticleData])) {
    // If the user doesn't have permission, return null and don't fetch data
    return { data: null };
  }

  const response = (await queryClient.fetchQuery(
    forumCouncilQueryOptions()
  )) as ActionResponse<any>;

  return {
    data: response.success ? response.data || null : null,
  };
}
