import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  Form,
  useLoaderData,
  LoaderFunctionArgs,
} from 'react-router-dom';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';
import { toast } from 'react-toastify';

import Input from '../../../components/shared/Input';
import DropDownSelect from '../../../components/shared/DropdownSelect/DropDownSelectWithIndicators';
import Button from '../../../components/shared/Button';
import FileInput, {
  ALLOWED_IMAGE_TYPES,
  FileTypeEnum,
} from '../../../components/shared/FileInput';
import FilePreview from '../../../components/shared/FilePreview';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';

import { hasPermission, showToast } from '../../../utils';
import OrganizationsStore from '../services/organizations.store';

import { ORGANIZATION_PATH } from '../../../constants';
import { useRegions } from '../../../context/regions-context';
import { usePermission } from '../../../hooks/usePermission';
import { useValidationRules } from '../../../hooks/useValidation';
import { ActionResponse, PermissionsEnum } from '../../../types';

import {
  OrganizationFormInterface,
  OrganizationInterface,
} from '../services/organizations.types';
const organizationsStore: OrganizationsStore = new OrganizationsStore();

const OrganizationForm = () => {
  const navigate = useNavigate();
  const { availableRegionsDropdownItems, activeRegion } = useRegions();
  const { t } = useTranslation();
  const {
    LETTERS_NUMBERS_SPACES_DASHES_VALIDATION_RULES,
    POSTAL_CODE_VALIDATION_RULES,
    REQUIRED_VALIDATION_RULE,
  } = useValidationRules();
  // Get loader data to populate 'edit' form
  const loaderData =
    useLoaderData() as ActionResponse<OrganizationInterface> | null;
  let editFormData: OrganizationInterface | undefined = undefined;

  /* Permissions */
  const formType = loaderData
    ? [PermissionsEnum.OrganizationEdit]
    : [PermissionsEnum.OrganizationCreate];
  const canStoreOrganization = usePermission(formType);

  if (loaderData && loaderData.success) {
    editFormData = loaderData?.data;
    if (editFormData) {
      editFormData.logo = loaderData.logo;
    }
  }

  useEffect(() => {
    if (loaderData?.success === false) {
      toast.error(t('institutions.institution_not_belong_to_region'));

      const timer = setTimeout(() => {
        navigate(ORGANIZATION_PATH);
      }, 1000);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderData?.success, navigate]);

  // React Hook Form methods and state initialization
  const methods = useForm<FieldValues>({
    defaultValues: {
      name: editFormData?.name || '',
      address: editFormData?.address || '',
      city: editFormData?.city || '',
      postalCode: editFormData?.postal_code || '',
      country: editFormData?.country || '',
      regionId: editFormData?.region_id || '',
      // status: editFormData?.status || '',
      logo: editFormData?.logo || '',
      domain: editFormData?.domain || '',
    },
  });

  let file;
  if (editFormData) {
    file = {
      filename: t('image.logo'),
      url: editFormData.logo,
    };
  }

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(); // Reset form when active region changes
  }, [activeRegion, reset]);

  /**
   * Handles form submission.
   */
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (!canStoreOrganization) return;

    const {
      name,
      address,
      city,
      postalCode,
      country,
      regionId,
      domain,
      logo,
      // status,
    } = data as OrganizationFormInterface;
    // const transformedStatus = status
    // 	? RegionStatusEnum.Active
    // 	: RegionStatusEnum.Inactive;

    let organizationData: any = {
      name,
      address,
      city,
      postalCode,
      country,
      regionId,
      domain,
      // status: transformedStatus,
    };

    if (editFormData && editFormData?.id) {
      if (typeof logo !== 'string') {
        organizationData.logo = logo;
      }
    } else {
      organizationData.logo = logo;
    }

    const { success, message } =
      editFormData && editFormData?.id
        ? await organizationsStore.updateOrganization(
            editFormData.id,
            organizationData
          )
        : await organizationsStore.addOrganization(organizationData);

    if (success) {
      navigate(-1);
    }

    showToast(message, success);
    reset({}, { keepValues: true });
  };

  return (
    <div>
      {isSubmitting && <TransitionLoader message={t('state.creating')} />}
      <FormProvider {...methods}>
        <Form className="pks-layout-col-md" onSubmit={handleSubmit(onSubmit)}>
          <div className="pks-layout-col-md">
            <div className="pks-layout-col">
              {/* Name Input */}
              <Input
                id="name"
                name="name"
                label={`${t('inputs.institution.label')}*`}
                placeholder={t('inputs.institution.placeholder')}
                autoFocus
                autoComplete="off"
                validationRules={{
                  ...LETTERS_NUMBERS_SPACES_DASHES_VALIDATION_RULES,
                  ...REQUIRED_VALIDATION_RULE,
                }}
                disabled={!canStoreOrganization}
                hasLock={!canStoreOrganization}
              />

              {/* Address Input */}
              <Input
                id="address"
                name="address"
                label={t('inputs.address.label')}
                placeholder={t('inputs.address.placeholder')}
                autoComplete="off"
                validationRules={LETTERS_NUMBERS_SPACES_DASHES_VALIDATION_RULES}
                disabled={!canStoreOrganization}
                hasLock={!canStoreOrganization}
              />

              <div className="flex flex-col gap-2 sm:flex-row">
                {/* City Input */}
                <div className="flex-grow">
                  <Input
                    id="city"
                    name="city"
                    label={t('inputs.city.label')}
                    placeholder={t('inputs.city.placeholder')}
                    autoComplete="off"
                    validationRules={
                      LETTERS_NUMBERS_SPACES_DASHES_VALIDATION_RULES
                    }
                    disabled={!canStoreOrganization}
                    hasLock={!canStoreOrganization}
                  />
                </div>
                {/* Postal Code Input */}
                <div className="sm:w-1/3">
                  <Input
                    id="postalCode"
                    name="postalCode"
                    label={t('inputs.postal_code.label')}
                    placeholder={t('inputs.postal_code.placeholder')}
                    autoComplete="off"
                    validationRules={POSTAL_CODE_VALIDATION_RULES}
                    maxLength={5}
                    numeric
                    disabled={!canStoreOrganization}
                    hasLock={!canStoreOrganization}
                  />
                </div>
              </div>
              {/* County Input */}
              <Input
                id="country"
                name="country"
                label={t('inputs.state.label')}
                placeholder={t('inputs.state.placeholder')}
                autoComplete="off"
                validationRules={LETTERS_NUMBERS_SPACES_DASHES_VALIDATION_RULES}
                disabled={!canStoreOrganization}
                hasLock={!canStoreOrganization}
              />
            </div>

            <div className="pks-layout-col">
              {/* Logo File Input */}
              <FileInput
                id="logo"
                name="logo"
                label={`${t('institutions.institution_logo')}:`}
                allowedTypes={ALLOWED_IMAGE_TYPES}
                maxSizeMB={5}
                type={FileTypeEnum.Logo}
                disabled={!canStoreOrganization}
                hasLock={!canStoreOrganization}
              />
              <p className="text-sm">
                {t('institutions.institution_logo_note')}
              </p>
              {/* File Preview */}
              {editFormData?.logo && <FilePreview file={file} />}
            </div>

            {/* Domain and Region */}
            <div className="pks-layout-col">
              {/* Domain Input */}
              <Input
                id="domain"
                name="domain"
                label={t('inputs.domain.label')}
                placeholder={t('inputs.domain.placeholder')}
                autoComplete="off"
                disabled={!canStoreOrganization}
                hasLock={!canStoreOrganization}
                //validationRules={DOMAIN_VALIDATION_RULES}
              />

              {/* Region Input */}
              <DropDownSelect
                id="regionId"
                name="regionId"
                options={availableRegionsDropdownItems}
                label={`${t('inputs.region.label')}*`}
                placeholder={t('inputs.region.placeholder')}
                validationRules={REQUIRED_VALIDATION_RULE}
                isDisabled={!canStoreOrganization}
                hasLock={!canStoreOrganization}
                //isLoading={isLoading || isFetching}
              />

              {/* Status Input */}
              {/* <CheckboxInput
								id='status'
								name='status'
								label='Aktivan'
							/> */}
            </div>
          </div>
          {/* Submit Button and Actions */}
          <div className="w-full inline-flex flex-wrap gap-4">
            <Button type="submit" className="flex-grow" disabled={isSubmitting}>
              {isSubmitting
                ? t('state.submitting')
                : editFormData
                ? t('institutions.edit_institution')
                : t('institutions.add_institution')}
            </Button>
            <span className="flex-auto sm:flex-none focus:outline-none group">
              <Button
                onClick={() => navigate(-1)}
                type="button"
                variant="secondary"
                wide
                tabIndex={-1}
              >
                {t('global.cancel')}
              </Button>
            </span>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default OrganizationForm;

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<
  ActionResponse<OrganizationInterface> | { success: false } | null
> {
  const slug = Number(params.id); // Assuming the route uses ":slug" as a param

  if (!hasPermission([PermissionsEnum.OrganizationData])) {
    return null;
  }

  if (slug) {
    // Preload form data
    const data = await organizationsStore.preloadOrganization(slug);

    if (!data?.success) {
      return { success: false };
    }

    const logoResData = await organizationsStore.getOrganizationLogo(slug);
    const { success } = logoResData;

    const newData = {
      ...data,
      logo: success ? logoResData.data : undefined,
    };

    return !data.success ? null : newData;
  }

  return null;
}
