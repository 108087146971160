import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import InfoMessage from '../../../components/shared/InfoMessage';
import Pagination from '../../../components/shared/Pagination';
import SearchInput from '../../../components/shared/SearchInput';
import ArchiveAccordion from './ArchiveAccordion';

import { hasPermission } from '../../../utils';
import { PermissionsEnum } from '../../../types';

import ArchiveStore from '../services/archive.store';
const archiveStore: ArchiveStore = new ArchiveStore();

const ROWS_PER_PAGE = 10;

const Archive = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { page: initialPage } = useLoaderData() as {
    page: number;
    q: string;
    rowsPerPage: number;
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');

  // Use the `useGetArchiveResults` hook from the search store to fetch search results based on the debounced search term
  const { data, error, isFetching } = archiveStore.useGetArchiveResults({
    page: initialPage,
    term: searchTerm,
  });

  const transformedData =
    data?.hits.map(({ id, title, content }) => ({
      id,
      question: title,
      answer: content,
    })) || [];

  // Calc total pages
  const totalPages = (data && Math.ceil(data.total / ROWS_PER_PAGE)) || 0;

  const handlePageChange = (newPage: number) => {
    if (newPage !== initialPage) {
      searchParams.set('page', newPage.toString()); // Update the page parameter

      // Update the search parameters in the URL
      setSearchParams(searchParams);
    }
  };

  const handleSearch = (debouncedValue: string): void => {
    setSearchTerm(debouncedValue);

    // Check if the current page is already 1, and avoid navigating
    if (searchParams.get('page') === '1') {
      return;
    }

    // Only navigate if the page is not already 1
    navigate(`?page=1`, {
      replace: true,
    });
  };

  return (
    <>
      <div className="pks-layout-col-md">
        {/* Search Input */}
        <SearchInput
          value={searchTerm}
          onSearch={handleSearch}
          autofocus
          isFetching={isFetching}
          minChars={4}
        />
        {/* Archive Data */}
        {error && (
          <InfoMessage
            icon="danger"
            message={`${t('error')} ${t('global.try_again')}`}
          />
        )}
        {data && data.hits.length === 0 && searchTerm.length >= 4 && (
          <InfoMessage icon="info" message={t('state.no_hits_search')} />
        )}
        {data && data.hits.length > 0 && (
          <>
            {/* Archive Accordion */}
            <ArchiveAccordion items={transformedData} />

            {/* Pagination */}
            <Pagination
              currentPage={initialPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Archive;

export const loader = async ({ request }: LoaderFunctionArgs): Promise<any> => {
  const { searchParams } = new URL(request.url);

  if (!hasPermission([PermissionsEnum.ArchiveSearch])) {
    return null;
  }

  const page = Number(searchParams.get('page')) || 1;

  await archiveStore.preloadArchiveResults({
    page,
  });

  return { page };
};
