import { useEffect, useRef } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import Icon from '../../shared/Icon';
import TransitionLoader from '../../shared/TransitionLoader/TransitionLoader';

import { ACTIVE_REGION_KEY } from '../../../constants';
import { usePaginatedDropdown } from '../../../hooks/usePaginatedDropdown';

import { useRegions } from '../../../context/regions-context';
import RegionsStore from '../../../modules/regions/services/regions.store';
import { useTranslation } from 'react-i18next';
const regionsStore: RegionsStore = new RegionsStore();

/**
 * A dropdown component that allows users to select from a list of available regions.
 * Handles fetching regions, storing active regions, and interacting with the UI state.
 */
const AvailableRegions = (): JSX.Element | null => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { id } = useParams();

  /**
   * Extract path segments to determine whether the dropdown should be hidden.
   * Hides the dropdown if the URL contains a segment starting with 'add-' or has an ID.
   */
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const lastSegment = pathSegments[pathSegments.length - 1];
  const shouldHideRegionsDropdown =
    lastSegment?.startsWith('add-') ||
    id ||
    location.pathname.includes('administration/organization/forum-council') ||
    location.pathname.endsWith('administration/organization') ||
    location.pathname.includes('/administration/database-instructions/');

  const [searchParams, setSearchParams] = useSearchParams();

  const shouldNavigateRef = useRef(false); // Tracks if navigation should occur after refetch.

  // Refs for dropdown state management
  const availableRegionsRef = useRef<HTMLDivElement>(null);
  const hasLabel = useRef(false);
  const hasLoadedData = useRef(false);

  // Context values for managing regions
  const {
    availableRegionsData,
    setAvailableRegionsData,
    activeRegion,
    setActiveRegion,
    isDropdownActive,
    onCloseDropdown,
    toggleDropdown,
    isRefetching,
    setIsRefetching,
  } = useRegions();

  /**
   * Custom hook to handle fetching paginated regions and transforming data.
   */
  const useRegionsDropdown = () => {
    return usePaginatedDropdown({
      fetchData: (page) =>
        regionsStore.useGetRegions({
          page,
          searchTerm: '',
          shouldFetch:
            availableRegionsData?.allRegions === 1 &&
            availableRegionsData.regions.length === 0
              ? true
              : false,
        }),
      extractItems: (data) => {
        return (data?.regions || []).map((region: any) => ({
          id: region.id,
          label: region.name,
        }));
      },
    });
  };

  // Extract paginated regions
  const { finalItems: allRegions } = useRegionsDropdown();

  /**
   * Initialize available regions data and set the active region on component load.
   */
  useEffect(() => {
    if (hasLoadedData.current === true) return;

    // If allRegions === 1 and allRegions is provided, update availableRegionsData with fetched data
    if (availableRegionsData?.allRegions === 1 && allRegions?.length > 0) {
      // Avoid unnecessary updates by checking if the data has changed
      setAvailableRegionsData((prevData) => {
        // Check if regions or any other required data needs to be updated
        const newRegions = allRegions.map((region) => ({
          id: region.id,
          name: region.label,
          init: true,
        }));

        const key = localStorage.getItem(ACTIVE_REGION_KEY);
        hasLoadedData.current = true;

        const activeRegion = key
          ? parseInt(key as string)
          : newRegions.length > 0
          ? newRegions[0].id
          : undefined;

        return {
          ...prevData,
          regions: newRegions,
          allRegions: prevData?.allRegions ?? 0,
          activeRegion: activeRegion,
        };
      });
    } else if (availableRegionsData?.allRegions === 0) {
      hasLoadedData.current = true;
    }
  }, [allRegions, availableRegionsData?.allRegions, setAvailableRegionsData]);

  /**
   * Sets the active region label when regions data is available.
   */
  useEffect(() => {
    if (hasLabel.current) return; // If already run, skip the effect

    if (availableRegionsData?.regions.length) {
      const activeRegionData =
        availableRegionsData?.regions.find(
          (region) =>
            Number(region.id) === Number(availableRegionsData.activeRegion)
        ) ||
        availableRegionsData?.regions?.[0] ||
        undefined;
      setActiveRegion({ ...activeRegionData, shouldRefetch: false });
      // Mark as done to prevent the effect from running again
      hasLabel.current = true;
    }
  }, [
    availableRegionsData?.activeRegion,
    availableRegionsData?.regions,
    setActiveRegion,
  ]);

  /**
   * Closes the dropdown if the user clicks outside of it.
   */
  useEffect(() => {
    if (!isDropdownActive) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (
        availableRegionsRef.current &&
        !availableRegionsRef.current.contains(event.target as Node)
      ) {
        onCloseDropdown();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownActive, onCloseDropdown]);

  /**
   * Navigates to the current route after a successful refetch.
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (shouldNavigateRef.current && isRefetching === false) {
        navigate(pathname, { replace: true });
        shouldNavigateRef.current = false;
      }
    }, 0);

    // Clean up timeout if the component is unmounted or the dependencies change
    return () => clearTimeout(timeout);
  }, [navigate, pathname, isRefetching]);

  /**
   * Synchronizes the active region when the active_region key changes in localStorage.
   */
  useEffect(() => {
    const handleStorage = (e: StorageEvent) => {
      if (!availableRegionsData?.regions?.length) return;
      // Check if the key that changed is 'active_region'
      if (e.key === ACTIVE_REGION_KEY) {
        const currentValue = localStorage.getItem(ACTIVE_REGION_KEY);

        const currentlyActiveRegion =
          currentValue &&
          availableRegionsData?.regions.find(
            (region) => region.id === +currentValue
          );

        if (!currentlyActiveRegion) return;

        handleClick({ ...currentlyActiveRegion, shouldRefetch: false });
      }
    };

    window.addEventListener('storage', handleStorage);

    return () => window.removeEventListener('storage', handleStorage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRegion, navigate, pathname]);

  // Return null if there are no regions to show
  if (
    availableRegionsData?.allRegions !== 1 &&
    (!availableRegionsData || availableRegionsData?.regions?.length <= 0)
  ) {
    return null;
  }

  // Exclude the currently active region from the dropdown list
  const regionsToShow = availableRegionsData.regions.filter(
    (region) => region.id !== activeRegion?.id
  );

  /**
   * Handles selecting a region and updates the active region state.
   * @param {Object} param0 - Selected region object.
   * @param {number} param0.id - Region ID.
   * @param {string} param0.name - Region name.
   */
  const handleClick = ({
    id,
    name,
    shouldRefetch = true,
  }: {
    id: number;
    name: string;
    shouldRefetch?: boolean;
  }) => {
    setIsRefetching(true);
    shouldNavigateRef.current = true;
    const updatedParams = new URLSearchParams(searchParams);

    // Check if the 'page' parameter exists in the URL
    if (updatedParams.has('page')) {
      // Remove the 'page' parameter
      updatedParams.delete('page');

      // Update the search parameters in the URL
      setSearchParams(updatedParams);
    }

    // Set active region
    setActiveRegion({ id, name, shouldRefetch });
  };

  return (
    <>
      {isRefetching && <TransitionLoader />}
      {!shouldHideRegionsDropdown && availableRegionsData.regions.length > 1 ? (
        <div
          className="bg-white text-center border-t border-b border-gray-300"
          ref={availableRegionsRef}
        >
          <button
            id={
              activeRegion?.id ? `reg-${activeRegion.id.toString()}` : undefined
            }
            className="flex w-full gap-1 items-center justify-center px-4 py-1"
            onClick={toggleDropdown}
          >
            <span>{t('regions.region')}:</span>
            <span>{activeRegion?.name}</span>
            <Icon
              name="chevronDown"
              className={`transition-transform duration-300 ${
                isDropdownActive ? 'rotate-180' : 'rotate-0'
              }`}
            />
          </button>
          {isDropdownActive && (
            <ul className="flex flex-col gap-1 max-h-[80vh] sm:max-h-80 overflow-auto scrollbar mb-1">
              {regionsToShow.map(({ id, name }) => (
                <li
                  id={`reg-${id.toString()}`}
                  className="hover:cursor-pointer hover:text-primary-300"
                  key={id}
                  onClick={handleClick.bind(null, { id, name })}
                >
                  {name}
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : null}
    </>
  );
};

export default AvailableRegions;
