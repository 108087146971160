import http from '../../../utils/http';

import { ArchiveApiProps } from './archive.types';

class ArchiveApi {
  /* Get Archive List */
  async getArchiveList({ term = '', page = 1, rowsPerPage }: ArchiveApiProps) {
    const params = new URLSearchParams();

    params.append('page', page.toString());

    if (term) {
      params.append('term', term);
    }

    if (rowsPerPage) {
      params.append('rowsPerPage', rowsPerPage.toString());
    }

    const url = `/archive${params.toString() ? `?${params.toString()}` : ''}`;

    return http.get(url);
  }
}

export default ArchiveApi;
