import {
  keepPreviousData,
  QueryKey,
  queryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import ArchiveApi from './archive.api';
import {
  ArchiveApiProps,
  ArchiveItemInterface,
  ArchiveItemSourceInterface,
  ArchiveResultsInterface,
} from './archive.types';
import { AxiosResponse } from 'axios';
import queryClient from '../../../config/tanstackQueryConfig';

const api: ArchiveApi = new ArchiveApi();

export const QUERY_KEY = 'archive';

class ArchiveStore {
  preloadArchiveResults = async ({ term = '', page = 1, rowsPerPage = 10 }) => {
    return await queryClient.fetchQuery(
      archiveQueryObject({
        term,
        page,
        rowsPerPage,
      })
    );
  };

  useGetArchiveResults = ({
    term = '',
    page = 1,
    rowsPerPage = 10,
    enabled = true,
  }) => {
    return useQuery(
      archiveQueryObject({
        term,
        page,
        rowsPerPage,
        enabled,
      })
    );
  };
}

const archiveQueryObject = ({
  term,
  page,
  rowsPerPage,
  enabled = true,
}: ArchiveApiProps): UseQueryOptions<ArchiveResultsInterface, Error> =>
  queryOptions({
    queryKey: [
      QUERY_KEY,
      { term: term.toLowerCase(), page, rowsPerPage },
    ] as QueryKey,
    queryFn: async () => {
      const response: AxiosResponse<ArchiveResultsInterface> =
        await api.getArchiveList({
          term: term.toLowerCase(),
          page,
          rowsPerPage,
        });

      const total = response.data?.total || 0;
      const hits: ArchiveItemSourceInterface[] = Array.isArray(
        response.data.hits
      )
        ? (response.data.hits as unknown as ArchiveItemInterface[]).map(
            (hit: ArchiveItemInterface) => ({
              id: hit._source.id,
              title: hit._source.title,
              content: hit._source.content,
            })
          )
        : [];

      if (total === 0) {
        return { hits: [], total };
      }

      return { hits, total };
    },
    enabled: enabled === true && term.length > 3,
    placeholderData: keepPreviousData,
  });

export default ArchiveStore;
