import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Icon from '../../../components/shared/Icon';

import { PermissionsEnum } from '../../../types';
import { usePermission } from '../../../hooks/usePermission';

import FraudCaseStore from '../services/fraudCases.store';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useInfiniteScrollSentinel } from '../../../hooks/useInfiniteScroll';
import InfoMessage from '../../../components/shared/InfoMessage';
const fraudCaseStore: FraudCaseStore = new FraudCaseStore();

const FraudCaseSingleFilesList = ({
  id,
  onOpenFile,
}: {
  id?: string;
  onOpenFile: (id: number) => {};
}) => {
  const { t } = useTranslation();

  const canViewFraudCaseFile = usePermission([
    PermissionsEnum.FraudcaseFileData,
  ]);
  const canDeleteFraudCaseFile = usePermission([
    PermissionsEnum.FraudcaseFileDelete,
  ]);
  const [isDeletingFile, setIsDeletingFile] = useState<{
    [key: number]: boolean;
  }>({});

  // Use the `useGetFraudCaseFiles` hook from the fraud cases store to fetch fraud case files data
  /* const { data: fraudCaseFilesData } = fraudCaseStore.useGetFraudCaseFiles({
    id: Number(id),
  }); */

  /* Permissions */
  const {
    data: fraudCaseFilesData,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  }: UseInfiniteQueryResult<
    any,
    Error
  > = fraudCaseStore.useGetFraudCaseFilesInfinite({ id: Number(id), page: 1 });

  /* Infinite scroll management */
  const filesSentinelRef = useRef<HTMLDivElement>(null);

  //const allFiles = fraudCaseFilesData?._embedded?.files || [];
  const allFiles = fraudCaseFilesData?.pages.flatMap(
    (page: any) => page?._embedded?.files || []
  );

  // Infinite Scroll
  useInfiniteScrollSentinel({
    sentinelRef: filesSentinelRef,
    hasNextPage,
    loadMore: fetchNextPage,
    isLoading: isFetchingNextPage,
  });

  const handleOpenFile = async (fileId: number) => {
    if (!canViewFraudCaseFile) return;

    onOpenFile(fileId);
  };

  const deleteFile = async ({ id, name }: { id: number; name: string }) => {
    if (!canDeleteFraudCaseFile) return;

    try {
      setIsDeletingFile((prev) => ({ ...prev, [id]: true }));
      await fraudCaseStore.deleteFraudCaseFile(id);
      toast.success(
        `${t('documents.document')} ${name} ${t(
          'global.is_successfully_removed'
        )}.`
      );
    } catch (error) {
      toast.error(`${t('documents.document_removal_error')} ${name}.`);
    } finally {
      setIsDeletingFile({});
    }
  };

  return allFiles?.length > 0 ? (
    <>
      <ul>
        {allFiles.map((file: any) => {
          const isFileChecked = file?.status === 1;

          return (
            <li key={file.id}>
              <span className="flex items-center gap-4">
                <button
                  className={`${
                    canViewFraudCaseFile && isFileChecked
                      ? 'cursor-pointer underline'
                      : 'cursor-default'
                  }`}
                  onClick={() =>
                    canViewFraudCaseFile && isFileChecked
                      ? handleOpenFile(file.id)
                      : null
                  }
                >
                  {file.filename}
                </button>
                {canDeleteFraudCaseFile && (
                  <>
                    {isDeletingFile[file.id] ? (
                      <span>
                        <Icon
                          name="loadingSpinner"
                          className="flex items-center size-6"
                        />
                      </span>
                    ) : (
                      <button
                        onClick={() =>
                          deleteFile({ id: file.id, name: file.name })
                        }
                      >
                        <Icon name="trash" className="flex items-center" />
                      </button>
                    )}
                  </>
                )}
                {!isFileChecked && (
                  <span className="text-sm flex items-center gap-1">
                    <Icon name="shieldCheck" />
                    {t('security_check_in_progress')}
                  </span>
                )}
              </span>
            </li>
          );
        })}
      </ul>
      {isFetchingNextPage && (
        <InfoMessage icon="info" message={t('state.loading')} />
      )}
      <div ref={filesSentinelRef} style={{ height: '1px' }} />
    </>
  ) : null;
};

export default FraudCaseSingleFilesList;
