import { useTranslation } from 'react-i18next';

import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import Archive from '../../modules/archive/UI';

import { usePermission } from '../../hooks/usePermission';
import { useRedirect } from '../../hooks/useRedirect';
import { PermissionsEnum } from '../../types';

function ArchivePage() {
  const { t } = useTranslation();
  const canSearch = usePermission([PermissionsEnum.ArchiveSearch]);
  useRedirect({ redirect: canSearch === false });

  const pageTitle = t('archive.archive');

  return (
    <>
      <Meta title={pageTitle} />
      <PageLayout title={pageTitle}>{canSearch && <Archive />}</PageLayout>
    </>
  );
}

export default ArchivePage;
