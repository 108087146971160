import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from '../../../components/shared/Button';
import DropDownSelect from '../../../components/shared/DropdownSelect/DropDownSelectWithIndicators';
import FileInput, {
  ALLOWED_DOC_TYPES,
} from '../../../components/shared/FileInput';
import Icon from '../../../components/shared/Icon';
import Input from '../../../components/shared/Input';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';
import UploadProgressModal, {
  useUploadProgress,
} from '../../../components/shared/UploadProgressModal';

import queryClient from '../../../config/tanstackQueryConfig';
import { DOCUMENTATION_TYPE_OPTIONS, EDUCATION_PATH } from '../../../constants';
import { useRegions } from '../../../context/regions-context';
import {
  extractMultipleErrors,
  hasNestedObjects,
  hasPermission,
  showToast,
} from '../../../utils';

import EducationStore, {
  educationDocumentOptions,
} from '../services/education.store';
import { PermissionsEnum } from '../../../types';
import { usePermission } from '../../../hooks/usePermission';
import { useValidationRules } from '../../../hooks/useValidation';

const educationStore: EducationStore = new EducationStore();

const EducationDocumentEntryForm = ({
  currentType,
}: {
  currentType: string;
}) => {
  const navigate = useNavigate();
  const { availableRegionsDropdownItems, activeRegion } = useRegions();
  const loaderData = (useLoaderData() as any) || null;
  const { t } = useTranslation();
  const { REQUIRED_VALIDATION_RULE } = useValidationRules();

  const isFileChecked = loaderData?.status === 1 ? true : false;

  /* Permissions */
  const documentFormType = loaderData
    ? [PermissionsEnum.DocumentUpdate]
    : [PermissionsEnum.DocumentsCreate];
  const canStoreDocument = usePermission(documentFormType);
  const canViewFile = usePermission([PermissionsEnum.DocumentFile]);

  const {
    uploadDetails,
    handleUploadProgress,
    handleUploadResponse,
    resetUploadProgress,
  } = useUploadProgress();

  const filename = loaderData?.filename || false;
  const [isLoadingFile, setIsLoadingFile] = useState(false);

  const handleOpenFile = async (fileId: number) => {
    if (!canViewFile || !isFileChecked) return;

    try {
      setIsLoadingFile(true);
      const response = await educationStore.getDocument(fileId, currentType);
      if (!response.data) {
        throw new Error('No URL found');
      }
      window.open(response.data.fileUrl, '_blank');
    } catch (error) {
      toast.error(t('error'));
    } finally {
      setIsLoadingFile(false);
    }
  };

  const [submitState, setSubmitState] = useState<{
    success: boolean | null;
    message?: string;
  }>({
    success: null,
    message: undefined,
  });

  useEffect(() => {
    if (submitState.success) {
      navigate(`${EDUCATION_PATH}/${currentType}`);
      toast.success(submitState.message);
    }
  }, [navigate, currentType, submitState]);

  const methods = useForm<FieldValues>({
    defaultValues: {
      title: loaderData?.title || '',
      regionId: loaderData?.regionId || [],
      type: loaderData?.type || '',
      document: loaderData?.document || '',
      details: loaderData?.details || '',
    },
  });

  const {
    handleSubmit,
    reset,
    setError,
    getValues,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (loaderData !== null) return;

    // Get the current value of `type`
    const currentType = getValues('type');

    // Reset all fields while preserving the `type` value
    reset({
      type: currentType,
    });
  }, [activeRegion, loaderData, reset, getValues]);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (!canStoreDocument) return;

    setSubmitState({
      success: null,
      message: undefined,
    });

    const handleError = (message: any) => {
      if (hasNestedObjects(message)) {
        // Set input fields errors
        const errorMessages = extractMultipleErrors(message);

        for (const key in errorMessages) {
          setError(key, {
            type: 'backend',
            message: errorMessages[key],
          });
        }
      } else {
        // Show toast error
        showToast(message, success);
      }
    };

    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (
        key === 'document' &&
        filename &&
        (!data.document || data.document.length === 0)
      ) {
        return; // Skip this field
      }

      if (!['type', 'regionId'].includes(key)) {
        formData.append(key, data[key]);
      }
    });

    if (data.regionId) {
      data.regionId.forEach((regionId: number) => {
        formData.append('regionIds[]', regionId.toString()); // Append each regionId as a string
      });
    }

    const response = loaderData?.id
      ? await educationStore.storeDocument({
          id: loaderData?.id || undefined,
          data: formData,
          type: data.type,
          onUpload: handleUploadProgress,
        })
      : await educationStore.storeDocument({
          data: formData,
          type: data.type,
          onUpload: handleUploadProgress,
        });

    const { success, message } = response;

    if (success) {
      if (data.document?.length > 0) {
        handleUploadResponse({ success: true });
      }
      setSubmitState({
        success: true,
        message,
      });
    } else {
      if (data.document?.length > 0) {
        handleUploadResponse({ success: false, code: response.code });
      }
      if (message !== null) {
        handleError(message);
      }

      setSubmitState({
        success: false,
        message: undefined,
      });
      const currentValues = getValues();
      const filteredValues = Object.keys(currentValues).reduce(
        (acc: Partial<FormData>, key) => {
          if (key !== 'document') {
            acc[key as keyof FormData] = currentValues[key]; // Keep only non-'documents' keys
          }
          return acc;
        },
        {}
      );
      if (response.code === 400) {
        reset(filteredValues, { keepValues: false, keepErrors: true });
      }
    }
  };

  function findIndexById(id: any) {
    return DOCUMENTATION_TYPE_OPTIONS.findIndex((item) => item.id === id);
  }

  const typeIndex = findIndexById(currentType);

  const transformedDocTypeOptions = DOCUMENTATION_TYPE_OPTIONS.map((type) => ({
    ...type,
    label: t(type.label),
  }));

  return (
    <>
      {isLoadingFile && !isSubmitting && (
        <TransitionLoader message={t('global.opening')} />
      )}
      <div className="max-w-[500px] relative">
        {isSubmitting && !uploadDetails.percentage && (
          <TransitionLoader message={t('state.creating')} />
        )}
        {uploadDetails.percentage && (
          <UploadProgressModal
            percentage={uploadDetails.percentage}
            error={uploadDetails.error}
            files={uploadDetails.files}
            onReset={resetUploadProgress}
            onSubmit={handleSubmit(onSubmit)}
          />
        )}

        <FormProvider {...methods}>
          <form className="pks-layout-col-md" onSubmit={handleSubmit(onSubmit)}>
            <div className="pks-layout-col-md">
              {/* Region and Doc Type */}
              <div className="pks-layout-col">
                {/* Region DropDown */}
                <DropDownSelect
                  id="regionId"
                  name="regionId"
                  label={`${t('inputs.add_region.label')}*`}
                  placeholder={t('regions.choose_region')}
                  options={availableRegionsDropdownItems}
                  validationRules={REQUIRED_VALIDATION_RULE}
                  //isLoading={isLoadingRegionsRef.current}
                  isDisabled={!canStoreDocument}
                  hasLock={!canStoreDocument}
                  multiple
                />
                {/* Doc Type Input */}
                <DropDownSelect
                  id="type"
                  name="type"
                  label={`${t('inputs.documentation_type.label')}*`}
                  placeholder={t('inputs.documentation_type.placeholder')}
                  options={transformedDocTypeOptions}
                  defaultValue={transformedDocTypeOptions[typeIndex]}
                  validationRules={REQUIRED_VALIDATION_RULE}
                  isDisabled={!canStoreDocument}
                  hasLock={!canStoreDocument}
                />
              </div>

              {/* Docs File Input */}
              <div className="pks-layout-col">
                <FileInput
                  id="document"
                  name="document"
                  required={!filename}
                  label={`${t('documents.add_document')}:*`}
                  maxSizeMB={100}
                  showFiles={true}
                  loadedFile={filename ? true : false}
                  allowedTypes={ALLOWED_DOC_TYPES} // Allowed file types
                  disabled={!canStoreDocument}
                  hasLock={!canStoreDocument}
                />
                <p className="text-sm">{t('documents.document_size_limit')}</p>
                {filename && canViewFile ? (
                  <div>
                    <span className="flex items-center gap-4">
                      <button
                        type="button"
                        className={`${
                          isFileChecked
                            ? 'cursor-pointer underline'
                            : 'cursor-default'
                        }`}
                        onClick={() => handleOpenFile(loaderData.id)}
                        disabled={!isFileChecked}
                      >
                        {filename}
                      </button>
                      {!isFileChecked && (
                        <span className="text-sm flex items-center gap-1">
                          <Icon name="shieldCheck" />
                          {t('security_check_in_progress')}
                        </span>
                      )}
                    </span>
                  </div>
                ) : null}
              </div>

              {/* Name and Description */}
              <div className="pks-layout-col">
                {/* Name Input */}
                <Input
                  id="title"
                  name="title"
                  label={`${t('inputs.document.label')}*`}
                  placeholder={t('inputs.document.placeholder')}
                  autoComplete="off"
                  validationRules={REQUIRED_VALIDATION_RULE}
                  disabled={!canStoreDocument}
                  hasLock={!canStoreDocument}
                />

                {/* Description Input */}
                <Input
                  id="details"
                  name="details"
                  label={`${t('inputs.description.label')}*`}
                  placeholder={t('inputs.description.placeholder')}
                  autoComplete="off"
                  validationRules={REQUIRED_VALIDATION_RULE}
                  height="80"
                  asTextarea
                  disabled={!canStoreDocument}
                  hasLock={!canStoreDocument}
                />
              </div>
            </div>
            {/* Submit Button and Actions */}
            <div className="w-full inline-flex flex-wrap gap-4">
              {canStoreDocument && (
                <Button type="submit" className="flex-grow">
                  {isSubmitting
                    ? t('state.submitting')
                    : loaderData?.id
                    ? t('documents.edit_document')
                    : t('documents.add_new_document')}
                </Button>
              )}
              <span className="flex-auto sm:flex-none">
                <Button
                  onClick={() => navigate(`${EDUCATION_PATH}/${currentType}`)}
                  type="button"
                  variant="secondary"
                  wide
                >
                  {t('global.cancel')}
                </Button>
              </span>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default EducationDocumentEntryForm;

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<any | null> {
  const { slug: type, id } = params;

  if (!id || !type) return null;

  if (!hasPermission([PermissionsEnum.DocumentData])) {
    // If the user doesn't have permission, return null and don't fetch data
    return null;
  }

  const { success, data } = await queryClient.fetchQuery(
    educationDocumentOptions({ id: Number(id), type })
  );

  if (success && data) {
    const regions =
      data.regions?.map((region: { id: number; name: string }) => region.id) ||
      [];

    const filename = data.filename.startsWith(`${id}__`)
      ? data.filename.substring(`${id}__`.length) // Remove the dynamic prefix
      : data.filename;

    const loaderData = {
      id: data.id,
      title: data.title,
      regionId: regions,
      type: data.type,
      details: data.details,
      filename: filename,
      status: data?.status || 0,
    };

    return loaderData;
  }

  return null;
}
