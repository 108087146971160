import { PermissionsEnum } from '../../../types';
/* Archive Permsissions */
export const archivePermissions = [PermissionsEnum.ArchiveSearch];

/* Article Permissions */
export const articlePermissions = [
  PermissionsEnum.ArticleCreate,
  PermissionsEnum.ArticleData,
];

/* Document Permissions */
export const documentPermissions = [
  PermissionsEnum.DocumentsCreate,
  PermissionsEnum.DocumentsList,
];

/* Fraud Cases Permissions */
export const fraudCasePermissions = [
  PermissionsEnum.FraudcasesList,
  PermissionsEnum.FraudcaseCreate,
];

/* NewsPermissions */
export const newsPermissions = [
  PermissionsEnum.NewsCreate,
  PermissionsEnum.NewsList,
];

/* OrganizationPermissions */
export const organizationPermissions = [
  PermissionsEnum.OrganizationsList,
  PermissionsEnum.OrganizationCreate,
];

/* Regions Permissions */
export const regionPermissions = [PermissionsEnum.RegionsList];

/* Roles Permissions */
export const rolePermissions = [PermissionsEnum.RolesList];

/* User Permissions */
export const userPermissions = [
  PermissionsEnum.UsersList,
  PermissionsEnum.UserCreate,
];

export const userListPermission = [PermissionsEnum.UsersList];
