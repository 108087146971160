import { createBrowserRouter } from 'react-router-dom';

import { ROUTES } from './constants';

/* * * Layouts * * */
import MainLayout from './layouts/MainLayout';
import AuthLayout from './layouts/AuthLayout';
import LoginLayout from './layouts/LoginLayout';

/* Pages */
import HomePage from './pages/Home';
import UnauthorizedPage from './pages/Unauthorized';
import ErrorPage from './pages/Error';

/* * * Auth * * */
import CreatePasswordPage from './pages/auth/CreatePassword';
import EmailVerificationPage from './pages/auth/EmailVerification';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import LoginPage from './pages/auth/Login';
import LogoutPage from './pages/auth/Logout';
import ResetPasswordPage from './pages/auth/ResetPassword';
/* * * Administration * * */
import ForumMembersPage from './pages/forumMembers';
import ForumMemberFormPage from './pages/forumMembers/ForumMemberForm';
import UserResetPasswordPage from './pages/forumMembers/ResetPassword';
import QuestionsAndAnswersPage from './pages/info/QuestionsAndAnswers';
import OrganizationsPage from './pages/organizations';
import OrganizationFormPage from './pages/organizations/OrganizationForm';
import RegionsPage from './pages/Regions';
import RolesPage from './pages/roles';
import InfoPage from './pages/info';
import ForumCouncil from './pages/info/ForumCouncil';
import DocumentsPage from './pages/documents';
import DocumentEntryPage from './pages/documents/DocumentsEntryPage';
import ArchivePage from './pages/archive';
/* * * Fraud Cases * * */
import FraudCasesPage from './pages/fraudCases';
import FraudCaseFormPage from './pages/fraudCases/FraudCaseForm';
import FraudCaseSinglePage from './pages/fraudCases/FraudCaseSingle';
/* * * Education * * */
/* Documents */
import EducationReportsPage from './pages/education';
import EducationDocumentEntryPage from './pages/education/EducationDocumentEntry';
/* News */
import NewsPage from './pages/news';
import NewsEntryPage from './pages/news/NewsEntry';
import NewsDetailsPage from './pages/news/NewsDetails';
/* * * Search * * */
import SearchResultsPage from './pages/searchResults';
/* * * Chat * * */
import ChatPage from './pages/ChatPage';

/* Services (Loaders and Actions) */

/* Auth */
import { action as logoutAction } from './pages/auth/Logout';
/* Administration */
import { loader as ForumMembersPageLoader } from './modules/forumMembers/UI/index';
import { loader as forumMemberFormLoader } from './modules/forumMembers/UI/ForumMembersForm';
import { loader as organizationsLoader } from './modules/organizations/UI/index';
import { loader as organizationFormLoader } from './modules/organizations/UI/OrganizationForm';
import { loader as regionsLoader } from './modules/regions/UI/index';
import { loader as rolesLoader } from './modules/roles/UI/index';
import { loader as infoLoader } from './pages/info/index';
import { loader as faqLoader } from './pages/info/QuestionsAndAnswers';
import { loader as forumCouncilLoader } from './pages/info/ForumCouncil';
import { loader as documentsLoader } from './modules/documents/UI/DocumentsEntryForm';
/* Fraud Cases */
import { loader as fraudCasesLoader } from './modules/fraudCases/UI/index';
import { loader as fraudCaseLoader } from './modules/fraudCases/UI/FraudCaseSingle';
/* Education */
import { loader as educationDocumentLoader } from './modules/education/UI/EducationDocumentEntryForm';
/* News */
import { loader as newsLoader } from './modules/news/UI/NewsForm';
/* Search */
import { loader as searchLoader } from './modules/search/UI/index';
/* Archive */
import { loader as archiveLoader } from './modules/archive/UI/index';

const router = createBrowserRouter([
  /* Auth */
  {
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [
      /* Auth */
      {
        element: <AuthLayout />,
        path: ROUTES.DASHBOARD.ROOT.relPath,
        children: [
          /* Home */
          {
            index: true,
            element: <HomePage />,
          },

          /**
           * Administration
           */
          {
            path: ROUTES.DASHBOARD.ADMINISTRATION.ROOT.relPath,
            children: [
              // Forum Members
              {
                children: [
                  {
                    index: true,
                    element: <ForumMembersPage />,
                    loader: ForumMembersPageLoader,
                  },
                  {
                    path: ROUTES.DASHBOARD.ADMINISTRATION.FORUM.FORUM_MEMBER_ADD
                      .relPath,
                    element: <ForumMemberFormPage />,
                  },
                  {
                    path: ROUTES.DASHBOARD.ADMINISTRATION.FORUM
                      .FORUM_MEMBER_EDIT.relPath,
                    element: <ForumMemberFormPage />,
                    loader: forumMemberFormLoader,
                  },
                  {
                    path: ROUTES.DASHBOARD.ADMINISTRATION.FORUM.RESET_PASSWORD
                      .relPath,
                    element: <UserResetPasswordPage />,
                    loader: forumMemberFormLoader,
                  },
                ],
              },

              // Organizations
              {
                path: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATIONS.ROOT
                  .relPath,
                children: [
                  {
                    index: true,
                    element: <OrganizationsPage />,
                    loader: organizationsLoader,
                  },
                  {
                    path: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATIONS
                      .ORGANIZATION_ADD.relPath,
                    element: <OrganizationFormPage />,
                  },
                  {
                    path: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATIONS
                      .ORGANIZATION_EDIT.relPath,
                    element: <OrganizationFormPage />,
                    loader: organizationFormLoader,
                  },
                ],
              },

              // Regions
              {
                path: ROUTES.DASHBOARD.ADMINISTRATION.REGIONS.ROOT.relPath,
                element: <RegionsPage />,
                loader: regionsLoader,
              },

              //Roles
              {
                path: ROUTES.DASHBOARD.ADMINISTRATION.ROLES.ROOT.relPath,
                element: <RolesPage />,
                loader: rolesLoader,
              },

              // Organization
              {
                path: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATION.ROOT.relPath,
                children: [
                  {
                    index: true,
                    element: <InfoPage />,
                    loader: infoLoader,
                  },
                  {
                    path: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATION.ARCHIVE
                      .relPath,
                    element: <ArchivePage />,
                    loader: archiveLoader,
                  },
                  {
                    path: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATION
                      .FORUM_COUNCIL.relPath,
                    element: <ForumCouncil />,
                    loader: forumCouncilLoader,
                  },
                  {
                    path: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATION.DOCUMENTS
                      .relPath,
                    children: [
                      {
                        index: true,
                        element: <DocumentsPage />,
                      },
                      {
                        path: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATION
                          .ADD_DOCUMENT.relPath,
                        element: <DocumentEntryPage />,
                      },
                      {
                        path: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATION
                          .EDIT_DOCUMENT.relPath,
                        element: <DocumentEntryPage />,
                        loader: documentsLoader,
                      },
                    ],
                  },
                ],
              },

              // Database Instructions
              {
                path: ROUTES.DASHBOARD.ADMINISTRATION.DATABASE_INSTRUCTIONS.ROOT
                  .relPath,
                children: [
                  {
                    index: true,
                    element: <InfoPage />,
                    loader: infoLoader,
                  },
                  {
                    path: ROUTES.DASHBOARD.ADMINISTRATION.DATABASE_INSTRUCTIONS
                      .INFO.relPath,
                    element: <InfoPage />,
                    loader: infoLoader,
                  },
                  //FAQ
                  {
                    path: ROUTES.DASHBOARD.ADMINISTRATION.DATABASE_INSTRUCTIONS
                      .FAQ.relPath,
                    element: <QuestionsAndAnswersPage />,
                    loader: faqLoader,
                  },
                ],
              },
            ],
          },
          // CHAT
          {
            path: ROUTES.DASHBOARD.CHAT.ROOT.absPath,
            element: <ChatPage />,
          },

          /**
           * Fraud Cases
           */
          {
            path: ROUTES.DASHBOARD.FRAUD_CASES.ROOT.relPath,
            children: [
              {
                index: true,
                element: <FraudCasesPage />,
                loader: fraudCasesLoader,
              },
              {
                path: ROUTES.DASHBOARD.FRAUD_CASES.FRAUD_CASE_ADD.relPath,
                element: <FraudCaseFormPage />,
              },
              /* {
								path: ROUTES.DASHBOARD.FRAUD_CASES
									.FRAUD_CASE_EDIT.relPath,
								element: <FraudCaseFormPage />,
								loader: fraudCaseLoader,
							}, */
              {
                path: ROUTES.DASHBOARD.FRAUD_CASES.FRAUD_CASE_DETAILS.relPath,
                element: <FraudCaseSinglePage />,
                loader: fraudCaseLoader,
              },
            ],
          },
          /* {
								path: ROUTES.DASHBOARD.FRAUD_CASES_OLD
									.FRAUD_CASE_DETAILS.relPath,
								element: <FraudCaseDetailsPage />,
							},
						],
					},

					/* Fraud Cases OLD*/
          /* {
						path: ROUTES.DASHBOARD.FRAUD_CASES_OLD.ROOT.relPath,
						children: [
							{
								index: true,
								element: <FraudCasesPage />,
								loader: fraudCasesLoader,
							},
							{
								path: ROUTES.DASHBOARD.FRAUD_CASES_OLD
									.FRAUD_CASE_DETAILS.relPath,
								element: <FraudCaseDetailsPage />,
							},
							{
								path: ROUTES.DASHBOARD.FRAUD_CASES_OLD
									.FRAUD_CASE_NEW.relPath,
								element: <FraudCaseNewPage />,
							},
						],
					}, */

          /* Education */
          {
            path: ROUTES.DASHBOARD.EDUCATION.ROOT.relPath,
            children: [
              {
                index: true,
                element: <EducationReportsPage />,
                // loader: educationReportsLoader,
              },
              // {
              // 	path: ROUTES.DASHBOARD.EDUCATION.SEMINARS
              // 		.relPath,
              // 			element: <EducationReportsPage />,
              // },
              {
                // path: ROUTES.DASHBOARD.EDUCATION.NEWS.relPath,
                children: [
                  {
                    path: ROUTES.DASHBOARD.EDUCATION.NEWS.relPath,
                    element: <NewsPage />,
                  },
                  {
                    path: ROUTES.DASHBOARD.EDUCATION.NEWS_DETAILS.absPath,
                    element: <NewsDetailsPage />,
                  },
                  {
                    path: ROUTES.DASHBOARD.EDUCATION.ADD_NEWS.absPath,
                    element: <NewsEntryPage />,
                  },
                  {
                    path: ROUTES.DASHBOARD.EDUCATION.EDIT_NEWS.absPath,
                    element: <NewsEntryPage />,
                    loader: newsLoader,
                  },
                  {
                    path: ROUTES.DASHBOARD.EDUCATION.ADD_DOCUMENT.absPath,
                    element: <EducationDocumentEntryPage />,
                  },
                  {
                    path: ROUTES.DASHBOARD.EDUCATION.EDIT_DOCUMENT.absPath,
                    element: <EducationDocumentEntryPage />,
                    loader: educationDocumentLoader,
                  },
                  {
                    path: ROUTES.DASHBOARD.EDUCATION.LISTS.absPath,
                    element: <EducationReportsPage />,
                  },
                ],
              },
            ],
          },

          /* Forum */
          /* {
						path: ROUTES.DASHBOARD.FORUM.ROOT.relPath,
						children: [
							{
								index: true,
								element: <ForumTopicsPage />,
							},
						],
					}, */

          /* Admin-PKS */
          /* {
						path: ROUTES.DASHBOARD.ADMIN.ROOT.relPath,
						children: [
							{
								path: ROUTES.DASHBOARD.ADMIN.USER_ENTRY.relPath,
								element: (
									<ProtectedRoute
										component={UserEntryPage}
										roles={[RoleEnum.Admin]}
									/>
								),
							},
						],
					}, */

          /* Unauthorized */ {
            element: <UnauthorizedPage />,
            path: ROUTES.DASHBOARD.UNAUTHORIZED.relPath,
          },
          /* Search Results */
          {
            element: <SearchResultsPage />,
            path: ROUTES.DASHBOARD.SEARCH.relPath,
            loader: searchLoader,
          },

          /* Unauthorized */
          {
            element: <UnauthorizedPage />,
            path: ROUTES.DASHBOARD.UNAUTHORIZED.relPath,
          },

          /* Logout */
          {
            element: <LogoutPage />,
            path: '/logout',
            action: logoutAction,
          },
        ],
      },

      {
        element: <ResetPasswordPage />,
        path: ROUTES.RESET.ROOT.absPath,
      },
      {
        element: <CreatePasswordPage />,
        path: ROUTES.CREATEPASSWORD.ROOT.absPath,
      },

      /* Auth */
      {
        element: <LoginLayout />,
        path: ROUTES.LOGIN.ROOT.absPath,
        children: [
          {
            index: true,
            element: <LoginPage />,
          },
          {
            path: ROUTES.LOGIN.EMAIL_VERIFICATION.relPath,
            element: <EmailVerificationPage />,
            /* action: emailVerificationAction, */
          },
          /* Logout */
          {
            path: ROUTES.LOGIN.FORGOT_PASSWORD.relPath,
            element: <ForgotPasswordPage />,
          },
        ],
      },
    ],
  },
]);

export default router;
