import { ROUTES } from '../../../constants';
import { InfoPageSlugEnum } from '../../../pages/info';
import { MenuDataProps } from '../../../types';
import {
  archivePermissions,
  articlePermissions,
  documentPermissions,
  fraudCasePermissions,
  newsPermissions,
  organizationPermissions,
  regionPermissions,
  rolePermissions,
  userListPermission,
  userPermissions,
} from './menuPermissions';

const databaseInstructionTypes = {
  userManual: InfoPageSlugEnum.UserManual,
  tnc: InfoPageSlugEnum.TNC,
};

/* Administration Permissions */
const administrationForumSubmenuPermissions = [
  ...userPermissions,
  ...organizationPermissions,
  ...regionPermissions,
  ...rolePermissions,
];

const administrationForumOrganizationSubmenuPermissions = [
  ...articlePermissions,
  ...documentPermissions,
];

const administrationDatabaseInstructionsSubmenuPermissions = articlePermissions;

const administrationMenuPermissions = [
  ...administrationForumSubmenuPermissions,
  ...administrationForumOrganizationSubmenuPermissions,
  ...administrationDatabaseInstructionsSubmenuPermissions,
];

/* Education and Information Permissions */
const educationAndInformationMenuPermissions = [
  ...newsPermissions,
  ...documentPermissions,
];

export const menuData: MenuDataProps[] = [
  /* Administration */
  {
    label: 'menu.administration',
    pathName: ROUTES.DASHBOARD.ADMINISTRATION.ROOT.absPath,
    permissions: administrationMenuPermissions,
    children: [
      {
        heading: 'menu.forum',
        permissions: administrationForumSubmenuPermissions,
        submenu: [
          {
            label: 'forum_members.title_forum_members',
            href: ROUTES.DASHBOARD.ADMINISTRATION.FORUM.ROOT.absPath,
            permissions: userPermissions,
          },
          {
            label: 'institutions.institutions',
            href: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATIONS.ROOT.absPath,
            permissions: organizationPermissions,
          },
          {
            label: 'regions.regions',
            href: ROUTES.DASHBOARD.ADMINISTRATION.REGIONS.ROOT.absPath,
            permissions: regionPermissions,
          },
          {
            label: 'roles.roles_and_permissions_title',
            href: ROUTES.DASHBOARD.ADMINISTRATION.ROLES.ROOT.absPath,
            permissions: rolePermissions,
          },
        ],
      },
      {
        heading: 'menu.forum_organization',
        permissions: administrationForumOrganizationSubmenuPermissions,
        submenu: [
          {
            label: 'articles.forum_terms_of_service',
            href: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATION.ROOT.absPath,
            permissions: articlePermissions,
          },
          {
            label: 'articles.forum_council',
            href: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATION.FORUM_COUNCIL
              .absPath,
            permissions: articlePermissions,
          },
          {
            label: 'documents.decisions',
            href:
              ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATION.ROOT.absPath +
              '/decisions',
            permissions: documentPermissions,
          },
          {
            label: 'documents.workreports',
            href:
              ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATION.ROOT.absPath +
              '/workreports',
            permissions: documentPermissions,
          },
          {
            label: 'archive.archive',
            href: ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATION.ARCHIVE.absPath,
            permissions: archivePermissions,
          },
        ],
      },
      {
        heading: 'menu.db_usage_instruction',
        permissions: administrationDatabaseInstructionsSubmenuPermissions,
        submenu: [
          {
            label: 'articles.user_guide',
            href:
              ROUTES.DASHBOARD.ADMINISTRATION.DATABASE_INSTRUCTIONS.ROOT
                .absPath +
              '/' +
              databaseInstructionTypes.userManual,
            permissions: articlePermissions,
          },
          {
            label: 'articles.faq',
            href: ROUTES.DASHBOARD.ADMINISTRATION.DATABASE_INSTRUCTIONS.FAQ
              .absPath,
            permissions: articlePermissions,
          },
        ],
      },
      // {
      //   heading: 'Forum Old',
      //   submenu: [
      //     {
      //       label: 'Forum',
      //       href: ROUTES.DASHBOARD.ADMINISTRATION.FORUMS.ROOT.absPath,
      //     },
      //     {
      //       label: 'Članovi foruma',
      //       href: ROUTES.DASHBOARD.ADMINISTRATION.FORUMS.FORUM_MEMBERS.absPath,
      //     },
      //     {
      //       label: 'Savet foruma',
      //       href: ROUTES.DASHBOARD.ADMINISTRATION.FORUMS.FORUM_COUNCIL.absPath,
      //     },
      //     {
      //       label: 'Izveštaji o radu foruma',
      //       href: ROUTES.DASHBOARD.ADMINISTRATION.FORUMS.FORUM_REPORTS.absPath,
      //     },
      //   ],
      // },
    ],
  },
  /* FraudCases */
  {
    label: 'menu.fraud_cases',
    href: ROUTES.DASHBOARD.FRAUD_CASES.ROOT.absPath,
    permissions: fraudCasePermissions,
  },
  /* Education and Information */
  {
    label: 'education.education_and_useful_information',
    pathName: ROUTES.DASHBOARD.EDUCATION.ROOT.absPath,
    permissions: educationAndInformationMenuPermissions,
    children: [
      {
        heading: 'education_types.conferences',
        permissions: documentPermissions,
        submenu: [
          {
            label: 'education.regular_forum_sessions',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/regular_sessions`,
          },
          {
            label: 'education.regional_forum_sessions',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/regional_sessions`,
          },
          {
            label: 'education.forum_participation_conferences',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/conferences`,
          },
        ],
      },
      {
        heading: 'menu.seminars',
        permissions: documentPermissions,
        submenu: [
          {
            label: 'education.forum_organized_seminars',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/seminars_by_forum`,
          },
          {
            label: 'education.forum_participation_seminars',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/seminars_participation`,
          },
          {
            label: 'education.forum_organized_trainings',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/education`,
          },
        ],
      },
      {
        heading: '',
        permissions: educationAndInformationMenuPermissions,
        submenu: [
          {
            label: 'education.east',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/east`,
            permissions: documentPermissions,
          },
          {
            label: 'education.laws_and_regulations',
            href: `${ROUTES.DASHBOARD.EDUCATION.ROOT.absPath}/laws`,
            permissions: documentPermissions,
          },
          {
            label: 'news.news_and_useful_information',
            href: ROUTES.DASHBOARD.EDUCATION.NEWS.absPath,
            permissions: newsPermissions,
          },
        ],
      },
    ],
  },
  {
    label: 'Chat',
    iconName: 'chatBubble',
    permissions: userListPermission,
    href: ROUTES.DASHBOARD.CHAT.ROOT.absPath,
    // children: [
    // {
    //   heading: 'Fraud Cases',
    //   submenu: [
    //     {
    //       label: 'Pregled Fraud Cases',
    //       href: ROUTES.DASHBOARD.FRAUD_CASES.ROOT.absPath,
    //     },
    //     // {
    //     //   label: 'Dodaj Fraud Case',
    //     //   href: ROUTES.DASHBOARD.FRAUD_CASES.FRAUD_CASE_ADD.absPath,
    //     // },
    //     // {
    //     //   label: 'Detalji Fraud Case-a',
    //     //   href: ROUTES.DASHBOARD.FRAUD_CASES.FRAUD_CASE_DETAILS.absPath,
    //     // },
    //   ],
    // },
    // ],
  },
  // {
  //   label: 'Chat',
  //   href: ROUTES.DASHBOARD.CHAT.ROOT.absPath,
  // },
];
