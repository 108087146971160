import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

import Icon from '../Icon';

import { decodeHtmlEntities } from '../../../utils';

interface AccordionProps {
  item: { id: number | string; question: string; answer: string };
  isOpen: boolean;
  onToggle: () => void;
}

const Accordion: React.FC<AccordionProps> = ({ item, isOpen, onToggle }) => {
  const { t } = useTranslation();

  // Sanitize answer but preserve all tags, and clean up unsafe content
  const sanitizedAnswer = DOMPurify.sanitize(item.answer, {
    ALLOWED_TAGS: [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'p',
      'strong',
      'em',
      'u',
      's',
      'blockquote',
      'ul',
      'ol',
      'li',
      'img',
      'a',
    ],
    ALLOWED_ATTR: ['class', 'href', 'target', 'rel', 'src'],
  });

  // Decode any HTML entities
  const decodedAnswer = decodeHtmlEntities(sanitizedAnswer);

  // Split the sanitized and decoded answer into parts and render text and links separately
  const getAnswerWithLinks = (answer: string) => {
    // Trim the answer and check if it's empty
    const trimmedAnswer = answer.trim();
    if (trimmedAnswer.length === 0) {
      return (
        <span className="text-sm text-secondary-300">{t('not_available')}</span>
      ); // Display "Not Available" if content is empty
    }

    // Split the sanitized and decoded answer into parts and render text and links separately
    const parts = trimmedAnswer.split(/\b(hXXps:\/\/[^\s]+)\b/gi); // Split text by URLs

    return parts.map((part, index) => {
      if (part.match(/^hXXps:\/\/[^\s]+/gi)) {
        const link = part.replace(/^hXXps/, 'https'); // Fix 'hXXps' typo
        return (
          <a key={index} href={link} target="_blank" rel="noopener noreferrer">
            <span className="inline-flex items-center underline hover:text-primary-300">
              {t('external_link')} <Icon name="externalLink" />
            </span>
          </a>
        );
      }
      return <span key={index} dangerouslySetInnerHTML={{ __html: part }} />; // Return the regular text parts, preserve HTML
    });
  };

  return (
    <div className="overflow-hidden border border-secondary-200 rounded-lg bg-white">
      <div
        className="flex gap-3 justify-between px-4 py-3 w-full cursor-pointer"
        onClick={onToggle}
      >
        <span className="font-bold select-none">
          {decodeHtmlEntities(item.question)}
        </span>
        <Icon className="flex items-center" name={isOpen ? 'minus' : 'plus'} />
      </div>
      {isOpen && (
        <div className="px-4 py-3 break-words">
          {getAnswerWithLinks(decodedAnswer)} {/* Use decodedAnswer here */}
        </div>
      )}
    </div>
  );
};

export default Accordion;
